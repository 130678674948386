<template>
  <div id="ContractEditPage">
    <div v-show="isLoading">
      <spin-loader />
    </div>
    <div v-show="!isLoading">
      <messager
        :module="'contract'"
        :thread="contractId"
        :header="data.bidding_set.client_set.name"
        @ready="syncLoad.ready('messager')"
      />
      <b-row class="justify-content-center mb-1">
        <b-col
          cols="12"
          md="11"
        >
          <div>
            <b-row>
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <b-dropdown
                  id="actions"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  right
                  class="dropdown-icon-wrapper"
                >
                  <template #button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="align-middle"
                    />
                  </template>

                  <b-dropdown-item
                    v-if="isViewMode"
                    id="bnt-edit"
                    :disabled="!hasPermissionToUpdate"
                    @click="enableEdition()"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Editar</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    :disabled="!hasPermissionToDelete"
                    @click="showConfirmOrCancelDelete(contractId)"
                  >
                    <svg-icon
                      type="mdi"
                      :path="mdiTrashCanOutline"
                    />
                    <span class="align-middle ml-50">Remover</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="11"
          lg="11"
        >
          <b-card
            class="mb-1 right-rounded overflow-hidden"
            no-body
          >
            <b-card-title class="m-0">
              <div class="d-flex justify-content-between contract-header">
                <div class="my-2 mx-2">
                  <b-row>
                    <b-col>
                      <span
                        class="d-inline contractEditTitle"
                      >
                        {{ data.bidding_set.client_set.name }}
                      </span>
                      <b-button
                        id="btn-link-contract"
                        type="button"
                        variant="outline-info"
                        class="mb-1 mb-sm-0 mr-0 p-0"
                        @click="goToBidding()"
                      >
                        <svg-icon
                          type="mdi"
                          :path="mdiLaunch"
                          size="18"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row
                    class="mt-50"
                  >
                    <b-col
                      class="d-flex align-items-center"
                    >
                      <span
                        class="mr-50 clsContractSubtitleLabel"
                      >
                        Nº Pregão:
                      </span>
                      <span
                        class="mr-50 clsContractSubtitleContent"
                      >
                        {{ data.bidding_set.trade_number }}
                      </span>
                      <b-button
                        id="btn-link-contract"
                        type="button"
                        variant="outline-info"
                        class="mb-1 mb-sm-0 mr-0 p-0"
                        @click="goToTrade()"
                      >
                        <svg-icon
                          type="mdi"
                          :path="mdiLink"
                          size="18"
                          style="color: #005C96"
                        />
                        {{ data.bidding_set.link_trade | truncate(35, '...') }}
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  class="btn-icon status-btn right-rounded"
                  :class="statusCircleClass"
                  variant="white"
                  :disabled="!hasPermissionToUpdate"
                  @click="changeContractState()"
                >
                  <svg-icon
                    type="mdi"
                    size="36"
                    class="statusSvg"
                    :path="statusCircleIcon"
                  />
                  <span
                    class="statusText"
                  >
                    {{ statusCircleText }}
                  </span>
                </b-button>
              </div>
            </b-card-title>
          </b-card>

          <validation-observer
            #default="{ handleSubmit, invalid }"
            ref="refFormObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(onSubmit)"
            >
              <b-card
                class="mb-1"
                no-body
              >
                <b-card-body>
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="contract_number"
                        :rules="{
                          required: true,
                          regex:/^[a-zA-Z0-9\-/]+$/
                        }"
                      >
                        <b-form-group
                          label="Nº da Ata/Contrato"
                          label-for="contract_number"
                        >
                          <b-form-input
                            id="contract_number"
                            v-model="data.number"
                            :state="isViewMode ? null : getValidationState(validationContext)"
                            name="contract_number"
                            :placeholder="'Nº da Ata/Contrato'"
                            :readonly="isViewMode"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <company-select
                        id="company"
                        v-model="data.company_set"
                        :readonly="true"
                        :required-field="true"
                        :truncate-field="60"
                        @ready="syncLoad.ready('company')"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="date_start_validity"
                        :rules="{
                          required: true,
                        }"
                      >
                        <b-form-group
                          label="Início da Vigência"
                          label-for="date_start_validity"
                        >
                          <b-form-datepicker
                            id="id_date_start_validity"
                            v-model="data.date_start"
                            :required="true"
                            locale="pt-BR"
                            :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                            size="md"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            label-no-date-selected="Nenhuma data foi selecionada"
                            selected-variant="primary"
                            :disabled="!hasPermissionToWrite || isViewMode"
                            placeholder="Selecione"
                            label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                            reset-button
                            reset-button-variant="info"
                            label-reset-button="Limpar"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="date_finish_validity"
                        :rules="{
                          required: true,
                        }"
                      >
                        <b-form-group
                          label="Fim da Vigência"
                          label-for="date_finish_validity"
                        >
                          <b-form-datepicker
                            id="id_date_finish_validity"
                            v-model="data.date_finish"
                            :required="true"
                            locale="pt-BR"
                            :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                            size="md"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            label-no-date-selected="Nenhuma data foi selecionada"
                            selected-variant="primary"
                            :disabled="!hasPermissionToWrite || isViewMode"
                            placeholder="Selecione"
                            label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                            reset-button
                            reset-button-variant="info"
                            label-reset-button="Limpar"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="email"
                        rules="email"
                      >
                        <b-form-group
                          label="E-mail"
                          label-for="e-mail"
                        >
                          <b-form-input
                            id="e-mail"
                            v-model="data.email"
                            :state="isViewMode ? null : getValidationState(validationContext)"
                            trim
                            placeholder="exemplo@syslic.com.br"
                            :readonly="isViewMode"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <contract-type-select
                        id="contract"
                        v-model="data.type_set"
                        :required-field="true"
                        :readonly="isViewMode"
                        @ready="syncLoad.ready('documentType')"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <scope-select
                        id="idScope"
                        v-model="data.scope_set"
                        :required-field="true"
                        :readonly="isViewMode"
                        @ready="syncLoad.ready('scope')"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <contract-status-select
                        id="status"
                        v-model="data.status_set"
                        :required-field="true"
                        :readonly="isViewMode"
                        @ready="syncLoad.ready('status')"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="observation"
                      >
                        <b-form-group
                          label="Observações"
                          label-for="observation"
                        >
                          <b-form-textarea
                            id="observation"
                            v-model="data.observation"
                            :state="isViewMode ? null : getValidationState(validationContext)"
                            trim
                            :placeholder="isViewMode ? '' : 'Observações'"
                            :readonly="isViewMode"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
              <b-card no-body>
                <b-tabs
                  class="contractTab"
                  card
                >
                  <b-tab
                    class="p-0"
                    :active="activeTab === dataTab.item"
                    @click="selectedTab(dataTab.item)"
                  >
                    <template #title>
                      <b-card-title>
                        <svg-icon
                          class="mr-50 contractIconTitle"
                          type="mdi"
                          :path="mdiPackageVariant"
                        />
                        <span class="d-inline">Itens</span>
                      </b-card-title>
                    </template>
                    <b-card-body
                      class="p-0"
                    >
                      <contract-items
                        ref="contractItemsRef"
                        :contract="contractId"
                        :readonly="data.state !== 'draft' || isViewMode"
                        @ready="syncLoad.ready('items')"
                      />
                    </b-card-body>
                  </b-tab>
                  <b-tab
                    class="p-0"
                    :active="activeTab === dataTab.attach"
                    @click="selectedTab(dataTab.attach)"
                  >
                    <template #title>
                      <b-card-title>
                        <svg-icon
                          class="mr-50 contractIconTitle"
                          type="mdi"
                          :path="mdiPaperclip"
                        />
                        <span class="d-inline">Anexos</span>
                      </b-card-title>
                    </template>
                    <b-card-body
                      class="p-0"
                    >
                      <contract-attach
                        v-model="data.file_set"
                        :contract-id="data.id"
                        :readonly="isViewMode"
                        @ready="syncLoad.ready('attach')"
                      />
                    </b-card-body>
                  </b-tab>
                </b-tabs>
              </b-card>
              <b-row class="mb-2">
                <b-col
                  class="d-flex justify-content-end buttonsSaveCancel"
                >
                  <b-button
                    v-if="!isViewMode"
                    id="btn-save"
                    type="submit"
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :disable="invalid"
                  >
                    Salvar
                  </b-button>
                  <b-button
                    id="itemCancelButton"
                    variant="outline-primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :to=" { name: 'contract' } "
                  >
                    Voltar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BFormTextarea,
  BButton,
  BTab,
  BTabs,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import {
  mdiTrashCanOutline,
  mdiLink,
  mdiLaunch,
  mdiLockAlertOutline,
  mdiLockCheckOutline,
  mdiPackageVariant,
  mdiPaperclip,
} from '@mdi/js'

import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'

import { useToast } from 'vue-toastification/composition'
import SvgIcon from '@jamescoyle/vue-icon'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import SyncLoad from '@/components/utils/syncLoad'
import ChangeDetector from '@/components/utils/changeDetector'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import truncate from '@/components/utils/filters/truncate'
import syslic from '@/syslic'
import router from '@/router'
import CompanySelect from '@/components/company/CompanySelect.vue'
import ContractTypeSelect from '@/components/contract/ContractTypeSelect.vue'
import ScopeSelect from '@/components/contract/ScopeSelect.vue'
import ContractStatusSelect from '@/components/contract/ContractStatusSelect.vue'
import ContractAttach from './components/ContractAttach.vue'
import Messager from '@/components/messager/Messager.vue'
import ContractItems from '@/views/contract/components/ContractItems.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    BFormTextarea,
    BButton,
    BTab,
    BTabs,
    BDropdown,
    BDropdownItem,
    ValidationObserver,
    ValidationProvider,
    SvgIcon,
    SpinLoader,
    CompanySelect,
    ContractTypeSelect,
    ScopeSelect,
    ContractStatusSelect,
    ContractAttach,
    ContractItems,
    Messager,
  },
  directives: {
    Ripple,
  },
  filters: {
    truncate,
  },
  data() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.data)

    const isLoading = true

    let isViewMode = true

    if (router.currentRoute.path.match(/.*\/edit\/.*/)
        || router.currentRoute.path.match(/.*\/create/)) {
      isViewMode = false
    }
    let contractId = ''
    if (router.currentRoute.path.match(/.*\/edit\/.*/)
        || router.currentRoute.path.match(/.*\/view\/.*/)) {
      contractId = router.currentRoute.params.id
    }

    const data = {
      bidding: '',
      bidding_set: {
        client_set: '',
      },
      date_finish: null,
      date_start: null,
      file_set: [],
      id: '',
      number: '',
      observation: '',
      owner: '',
      owner_set: '',
      state: 'draft',
      scope: '',
      scope_set: '',
      status: '',
      status_set: '',
      type: '',
      type_set: '',
      is_outdated: '',
    }

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('contract')
    syncLoad.addTrigger('company')
    syncLoad.addTrigger('documentType')
    syncLoad.addTrigger('scope')
    syncLoad.addTrigger('status')
    syncLoad.addTrigger('items')
    syncLoad.addTrigger('attach')
    syncLoad.addTrigger('messager')

    syncLoad.onReady(() => {
      this.isLoading = false
    })

    const dataTab = {
      item: 1,
      attach: 2,
    }

    const changeDetector = new ChangeDetector()

    const activeTab = router.currentRoute.params.tab || dataTab.item

    return {
      refFormObserver,
      getValidationState,
      isLoading,
      isViewMode,
      data,
      syncLoad,
      dataTab,
      activeTab,
      contractId,
      changeDetector,
    }
  },
  computed: {
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.contract.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.contract.can_delete
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.contract.can_write
    },
    statusCircleClass() {
      const stateClassMap = {
        suspended: 'bg-red-contract-button',
        released: 'bg-green-contract-button',
        draft: 'bg-gray-contract-button',
      }

      return stateClassMap[this.data.state] || ''
    },
    statusCircleText() {
      const stateTextMap = {
        suspended: 'Contrato Suspenso',
        released: 'Contrato Liberado',
        draft: 'Contrato Pendente',
      }

      return stateTextMap[this.data.state] || ''
    },
    statusCircleIcon() {
      const stateCircleMap = {
        suspended: mdiLockAlertOutline,
        released: mdiLockCheckOutline,
        draft: mdiLockAlertOutline,
      }

      return stateCircleMap[this.data.state] || ''
    },
  },
  watch: {
    'data.company_set': function updateCompany(val) {
      if (val) {
        this.data.company = `${val.id}`
      } else {
        this.data.company = ''
      }
    },
    'data.type_set': function updateDocumentType(val) {
      if (val) {
        this.data.type = val.id
      } else {
        this.data.type = ''
      }
    },
    'data.scope_set': function updateScope(val) {
      if (val) {
        this.data.scope = val.id
      } else {
        this.data.scope = ''
      }
    },
    'data.status_set': function updateStatus(val) {
      if (val) {
        this.data.status = val.id
      } else {
        this.data.status = ''
      }
    },
  },
  created() {
    this.fetchData()
    this.changeDetector.bind(this, ['data'])
  },
  methods: {
    changeContractState() {
      if (this.changeDetector.hasChanges() || this.$refs.contractItemsRef.hasChanges()) {
        return
      }

      const contractState = {
        draft: {
          nextState: 'released',
          confirmFunction: this.showConfirmReleaseContract,
        },
        released: {
          nextState: 'suspended',
          confirmFunction: this.showConfirmBlockContract,
        },
        suspended: {
          nextState: 'released',
          confirmFunction: this.showConfirmReleaseContract,
        },
      }

      const currentState = this.data.state
      const nextStateInfo = contractState[currentState]

      if (nextStateInfo) {
        const { nextState, confirmFunction } = nextStateInfo
        confirmFunction.call(this, { state: nextState })
      }
    },
    selectedTab(value) {
      this.activeTab = value
    },
    fetchData() {
      this.contractId = router.currentRoute.params.id
      if (this.contractId !== undefined) {
        syslic
          .contract
          .fetchContract(this.contractId)
          .then(response => {
            this.data = response.data
            this.syncLoad.ready('contract')
            this.changeDetector.reset()
          })
          .catch(err => {
            if (err.status === 404) {
              router.push({ name: 'error-404' })
            }
            this.syncLoad.ready('contract')
          })
      } else {
        this.syncLoad.ready('contract')
      }
    },
    updateContractPatch(data) {
      syslic
        .contract
        .updateContractPatch(this.data.id, data)
        .then(() => {
          this.data = { ...this.data, ...data }
          this.changeDetector.reset()
        })
        .catch(error => {
          let textError = 'Não foi possível atualizar o contrato, por favor entre em contato com o administrador do sistema.'
          let variantType = 'danger'

          if ('data' in error) {
            if (error.data[0].includes('There are released contracts in this bidding')) {
              textError = 'Não foi possível liberar este contrato, pois já existe um contrato liberado para esta licitação.'
              variantType = 'warning'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar o Contrato.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: variantType,
              timeout: false,
            },
          })
        })
    },
    updateContract() {
      syslic
        .contract
        .updateContract(this.data.id, this.data)
        .then(() => {
          const promise = this.$refs.contractItemsRef.changeContractItemValues()
          return promise
        })
        .then(() => {
          this.$refs.contractItemsRef.fetchItemsList()
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Contrato atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.changeDetector.reset()
          this.disableEdition()
        })
        .catch(error => {
          let textError = 'Não foi possível atualizar este contrato, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.non_field_errors[0].includes('must make a unique set')) {
              textError = 'Já existe um contrato com o mesmo número para licitação deste contrato.'
            }
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar o Contrato.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmReleaseContract(data) {
      this.$bvModal
        .msgBoxConfirm('Liberar esse contrato para criação de pedido e empenhos?', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
          footerClass: 'd-flex justify-content-center',
          bodyClass: 'text-center',
        })
        .then(value => {
          if (value) {
            this.disableEdition()
            this.updateContractPatch(data)
          }
        })
    },
    showConfirmBlockContract(data) {
      this.$bvModal
        .msgBoxConfirm('Deseja bloquear esse contrato? Não será possível criar pedidos e empenhos a partir dele', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
          footerClass: 'd-flex justify-content-center',
          bodyClass: 'text-center',
        })
        .then(value => {
          if (value) {
            this.disableEdition()
            this.updateContractPatch(data)
          }
        })
    },
    goToTrade() {
      const link = this.data.bidding_set.link_trade
      if (link) window.open(link, '_blank')
    },
    goToBidding() {
      const routeData = router.resolve({
        name: 'bidding-view',
        params: {
          id: this.data.bidding_set.id,
        },
      })
      window.open(routeData.href, '_blank')
    },
    onSubmit() {
      this.updateContract()
    },
    deleteContract(item) {
      syslic
        .contract
        .deleteContract(item)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Contrato removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          router.push({ name: 'contract' })
        })
        .catch(error => {
          let textError = 'Não foi possível remover o contrato do servidor, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.includes('they are referenced through protected foreign keys')) {
              textError = 'Este contrato é referenciado em algum pedido, por este motivo não será possível exclui-lo.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover contrato.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você quer remover o contrato.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteContract(item)
          }
        })
    },
    enableEdition() {
      router.push({
        name: 'contract-edit',
        params: {
          id: this.contractId,
          tab: this.activeTab,
        },
      })
        .catch(() => {})
    },
    disableEdition() {
      if (router.currentRoute.name !== 'contract-detail') {
        router.push({
          name: 'contract-detail',
          params: {
            id: this.contractId,
            tab: this.activeTab,
          },
        })
          .catch(() => {})
      }
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiTrashCanOutline,
      mdiLink,
      mdiLaunch,
      mdiLockAlertOutline,
      mdiLockCheckOutline,
      mdiPackageVariant,
      mdiPaperclip,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#ContractEditPage {
  transform: none !important;
}

.contract-header {
  background-color: $table-hover-bg;
}
.contractEditTitle {
  color: $primary;
  text-transform: uppercase;
}

#btn-link-contract {
  border: 0px !important;
}

.clsContractSubtitleLabel {
  color: #005C96;
  font-size: 1rem;
  font-weight: bolder;
}

.clsContractSubtitleContent {
  color: #494949;
  font-size: 1rem;
}
.buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}
.bg-red-contract-button {
  background-color: #EF2828;
  color: #EF2828;
}

.bg-green-contract-button {
  background-color: #049F14;
  color: #049F14;
}

.bg-gray-contract-button {
  background-color: #777777;
  color: #777777;
}

.status-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .4em;
  max-width: 9em;
}

.right-rounded {
  border-radius: 0 1em 1em 0;
}

.statusText {
  color: #ffffff;
  font-size: .9em;
  font-weight: 600;
  margin: 0;
}

.statusSvg {
  background-color: #ffffff;
  border-radius: 50%;
  padding: .4em;
}

#observation {
  min-height: 7.2rem;
}

.contractIconTitle {
  width: 21px;
  height: 21px;
}

.contractTab .card-header {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.contractTab .nav-link {
  padding-left: 2rem;
  padding-right: 2rem;
}

.contractTab .nav-link h4 {
  color: #6e6b7b;
  font-size: 1rem;
}

.contractTab .nav-link.active {
  background-color: #e2edf3;
}

.contractTab .nav-link.active h4 {
  color: $primary;
}

.contractTab .nav-link:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}
 .contractTab .card-body {
  padding-left: 1.0rem;
  padding-right: 1.0rem;
 }

 .contractTab .tab-content {
   margin-top: -1rem;
 }
</style>
