<template>
  <div>
    <div id="idItemListList">
      <b-row
        id="contractProductListHeader"
        class="pt-1 pb-1"
      >
        <b-col
          cols="12"
          md="12"
          class="contractCardItemBlock"
        >
          <div
            class="justify-content-end d-flex mr-1"
          >
            <div
              class="mr-2"
            >
              <span
                class="contractCardItemSectionTitle"
              >
                VALOR DO CONTRATO:
                <span
                  class="contractCardItemSectionValue"
                >
                  R$ {{ contractTotalValue | formatCurrency() }}
                </span>
              </span>
            </div>
            <div
              class="ml-2"
            >
              <span
                class="contractCardItemSectionTitle"
              >
                SALDO DO CONTRATO:
                <span
                  class="contractCardItemSectionValue"
                >
                  R$ {{ contractTotalBalanceValue | formatCurrency() }}
                </span>
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
        >
          <b-table
            ref="refItemTable"
            thead-class="itemTableHeader"
            tbody-tr-class="itemTableRow"
            primary-key="id"
            responsive
            show-empty
            striped
            hover
            empty-text="Não há itens cadastrados."
            :items="items"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            :busy="isLoading"
          >
            <template #table-busy>
              <div class="text-center my-3">
                <b-spinner
                  variant="info"
                  class="align-middle mb-1"
                />
                <br>
                <strong class="pt-2">Carregando...</strong>
              </div>
            </template>

            <template #cell(field_item)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.item_set.number }}
                </span>
              </b-media>
            </template>

            <template #cell(field_lote)="field">
              <b-media vertical-align="center">
                <span
                  v-if="field.item.item_set.parent_set"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.item_set.parent_set.name }}
                </span>
                <span
                  v-else
                  class="font-weight-bold d-block text-nowrap"
                >
                  -
                </span>
              </b-media>
            </template>

            <template #cell(field_name)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.item_set.name }}
                </span>
              </b-media>
            </template>

            <template #cell(field_unit_value)="field">
              <b-media vertical-align="center">
                <b-form-group
                  label-for="price"
                  class="custom-margin"
                >
                  <cleave-customized
                    v-if="!readonly"
                    id="price"
                    v-model="field.item.item_set.price"
                    :option="options.price"
                    :places="2"
                    :required-field="true"
                    :readonly="readonly"
                  />
                  <span
                    v-else
                    id="price"
                    class="font-weight-bold d-block text-nowrap"
                  >
                    R$ {{ field.item.item_set.price | formatCurrency() }}
                  </span>
                </b-form-group>
              </b-media>
            </template>

            <template #cell(field_estimated_cost)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  R$ {{ field.item.item_set.cost | formatCurrency() }}
                </span>
              </b-media>
            </template>

            <template #cell(field_current_cost)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  R$ {{ getCurrentCost(field.item.item_set) | formatCurrency() }}
                </span>
              </b-media>
            </template>

            <template #cell(field_item_quantity)="field">
              <b-media vertical-align="center">
                <b-form-group
                  label-for="quantity"
                  class="custom-margin"
                >
                  <b-form-input
                    v-if="!readonly"
                    id="quantity"
                    v-model="field.item.item_set.quantity"
                    class="font-weight-bold d-block text-nowrap"
                    type="number"
                    oninput="validity.valid||(value='');"
                    @input="convertQuantity(items.indexOf(field.item))"
                  />
                  <span
                    v-else
                    id="quantity"
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ field.item.item_set.quantity }}
                  </span>
                </b-form-group>
              </b-media>
            </template>

            <template #cell(field_item_balance)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.balance }}
                </span>
              </b-media>
            </template>

            <template #cell(field_total_value_balance)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  R$ {{ field.item.item_set.price * field.item.balance | formatCurrency() }}
                </span>
              </b-media>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BTable,
  BMedia,
  VBTooltip,
  BSpinner,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'

import {
  mdiAlphaUBoxOutline,
  mdiAlphaCBox,
} from '@mdi/js'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import formatCurrency from '@/components/utils/filters/formatCurrency'
import CleaveCustomized from '@/components/utils/CleaveCustomized.vue'
import syslic from '@/syslic'
import router from '@/router'
import ChangeDetector from '@/components/utils/changeDetector'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BMedia,
    BFormGroup,
    BFormInput,
    BSpinner,
    CleaveCustomized,
  },
  filters: {
    formatCurrency,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    contract: {
      type: [String, Number],
      required: true,
      default: null,
    },
    readonly: {
      type: [Boolean],
      required: true,
    },
  },
  data() {
    const isLoading = true
    const contractTotalValue = 0
    const contractTotalBalanceValue = 0
    const estimatedTotalCostValue = 0

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.data)

    const changeDetector = new ChangeDetector()

    const sortBy = 'name'
    const isSortDirDesc = false
    const tableColumns = [
      {
        key: 'field_item',
        label: 'Nº DO ITEM',
        thStyle: { width: '10%' },
      },
      {
        key: 'field_lote',
        label: 'lote',
        thStyle: { width: '5%' },
      },
      {
        key: 'field_name',
        label: 'Nome do produto',
        thStyle: { width: '15%' },
      },
      {
        key: 'field_unit_value',
        label: 'Valor Unitário',
        thStyle: { width: '11%' },
      },
      {
        key: 'field_estimated_cost',
        label: 'Custo Estimado',
        thStyle: { width: '12%' },
      },
      {
        key: 'field_current_cost',
        label: 'Custo Atual',
        thStyle: { width: '10%' },
      },
      {
        key: 'field_item_quantity',
        label: 'Qtd De Itens',
        thStyle: { width: '10%' },
      },
      {
        key: 'field_item_balance',
        label: 'Saldo De Itens',
        thStyle: { width: '12%' },
      },
      {
        key: 'field_total_value_balance',
        label: 'Saldo Do Valor Total',
        thStyle: { width: '15%' },
      },
    ]

    const options = {
      price: {
        numeral: true,
        numeralDecimalScale: 2,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralPositiveOnly: true,
      },
    }

    const items = []

    return {
      refFormObserver,
      getValidationState,
      isLoading,
      sortBy,
      tableColumns,
      isSortDirDesc,
      items,
      contractTotalValue,
      contractTotalBalanceValue,
      estimatedTotalCostValue,
      options,
      changeDetector,
    }
  },
  watch: {
    items: {
      handler: function updateTotal() {
        this.getBalanceValue()
        this.getContractValue()
      },
      deep: true,
    },
  },
  created() {
    this.fetchItemsList()
    this.changeDetector.bind(this, ['items'])
  },
  methods: {
    fetchItemsList() {
      this.isLoading = true

      syslic
        .contract
        .item
        .getItems(this.contract)
        .then(response => {
          this.items = response.data.items
          this.isLoading = false
          this.$refs.refItemTable.refresh()
          this.changeDetector.reset()
          this.$emit('ready', true)
        })
        .catch(err => {
          if (err.status === 404) {
            router.push({ name: 'error-404' })
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao carregar itens do contrato.',
              text: 'Não foi possível carregar os itens deste contrato, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.isLoading = false
          this.$emit('ready', true)
        })
    },
    getContractValue() {
      let total = 0

      this.items.forEach(val => {
        total += Number(val.item_set.price) * Number(val.item_set.quantity)
      })
      this.contractTotalValue = total
    },
    getBalanceValue() {
      let total = 0

      this.items.forEach(val => {
        total += Number(val.item_set.price) * Number(val.balance)
      })

      this.contractTotalBalanceValue = total
    },
    getCurrentCost(item) {
      let total = 0

      if (item) {
        item.product_list.forEach(val => {
          total += Number(val.product_set.price) * Number(val.quantity)
        })
      }
      return total
    },
    changeContractItemValues() {
      const promiseList = []
      this.items.forEach(val => {
        const promise = null
        const fields = {
          price: val.item_set.price,
          quantity: val.item_set.quantity,
        }
        syslic.contract.item.updateItem(val.item_set.id, fields)
        promiseList.push(promise)
      })
      return Promise.all(promiseList)
    },
    convertQuantity(index) {
      this.items[index].item_set.quantity = parseInt(this.items[index].item_set.quantity, 10)
    },
    hasChanges() {
      return this.changeDetector.hasChanges()
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiAlphaUBoxOutline,
      mdiAlphaCBox,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idItemListList .itemTableHeader th {
  color: $primary;
  font-weight: 600;
  font-size: 0.9rem;
  background-color: white;
  border: none;
}

#idItemListList .itemListHeader {
  background-color: #F6F6F9
}

#idItemListList .itemTableRow {
  cursor: default;
}
#idItemListList .headerTitle {
  color: $primary;
  font-weight: 600;
  font-size: 1.25rem;
}

#idItemListList .headerContent {
  color: #464646;
}

#idItemListList .itemIconType {
  color: $primary;
}

#contractProductListHeader {
  background-color: #E3EDF3;
  margin: 0rem !important;
}

.contractCardItemSectionTitle {
  font-weight: 600;
  font-size: 1rem;
  color: #005C96;
  margin-top: 0.75rem;
}

.contractCardItemBlock .media{
  margin-bottom: 0.5rem;
}

.contractCardItemBlock .media-body{
  color: #5e5873;
  margin-top: 0.5rem;
}

.contractCardItemBlock.left {
  border-left: 1px solid #ebe9f1;
}

.contractCardItemSectionValue {
  color: #5B5B5B;
}

.custom-margin {
  margin-bottom: 0rem !important;
}
</style>
